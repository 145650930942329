export function FooterContent() {
  return (
    <div className="Landing_Body_Information">
      <div className="Landing_Body_Information_Title">
        NƠI CUNG CẤP
        <span style={{ color: "#6B4AE4" }}> MỌI NỘI DUNG GIẢI TRÍ </span>
        CHO BẠN !
      </div>
      <div className="Landing_Body_Information_Content">
        “ Chúng tôi mang đến cho bạn một nền tảng giải trí đa dạng với các nội dung hấp dẫn, bao gồm phim điện ảnh nổi tiếng, phim 18+ và các môn thể thao sôi động. Tất cả đều được cập nhật nhanh chóng để đảm bảo bạn không bỏ lỡ bất kỳ khoảnh khắc thú vị nào.  ”
      </div>
      <div className="Landing_Body_Information_Guarantee">
        {[
          "Cung cấp link an toàn.",
          "Xem miễn phí.",
          "Thao tác copy và điền link dễ dàng.",
          "Cá nhân hoá không gian xem.",
          "Các nền tảng nổi tiếng",
        ].map((text, index) => (
          <div className="Landing_Body_Information_Guarantee_Box" key={index}>
            <img
              src={`${process.env.PUBLIC_URL}/images/tick-circle.png`}
              alt="Guarantee"
            />
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}
