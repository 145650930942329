import React, { useState, useEffect, useRef } from "react";
import "../App.scss";
import { tabs } from "./ProviderPage";
import { FooterContent } from "./FooterContent";
import { Link } from "react-router-dom";

export function Landing() {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 1200);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Landing">
      <div className="Landing_Header">
        <div className="Landing_Header_Text">
          {/* <div style={{ whiteSpace: "nowrap", color: "#6B4AE4" }}>
            Bước 1: &nbsp;
          </div> */}
          <div>Hôm nay bạn muốn xem gì?</div>
        </div>
      </div>
      <div className="Landing_Body">
        <div className={`Landing_Body_Tablist fade-in`}>
          {tabs.map((tab, index) => (
            <Link
              className="Landing_Body_Tablist_Item"
              key={index}
              to={tab.path}
            >
              <div
                className={`Landing_Body_Tablist_Item_Image tab${index + 1}`}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/${tab.url}.png`}
                  alt={tab.url}
                  width={310}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/${tab.url}_inner.png`}
                  className="Landing_Body_Tablist_Item_Image_Inner"
                />
              </div>
              <div className="Landing_Body_Tablist_Item_Text">
                <div className="Landing_Body_Tablist_Item_Text_Left">
                  <div className="Landing_Body_Tablist_Item_Text_Left_Title">
                    {tab.title}
                  </div>
                  <div className="Landing_Body_Tablist_Item_Text_Left_Description">
                    {tab.description}
                  </div>
                </div>
                <div className="Landing_Body_Tablist_Item_Text_Right">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Icon.png`}
                    alt="Arrow"
                  />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <FooterContent />
      </div>
    </div>
  );
}
